import { createGlobalStyle, css } from 'styled-components';
import localFont from 'next/font/local';
import theme from './theme';

//! Space Generator
function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

//! Color Generator
function colorGenerator() {
	const colorsArr = Object.keys(theme.colors);

	let colorVariable = '';

	for (let i = 0; i < colorsArr.length; i++) {
		colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`;
	}

	return colorVariable;
}

//! Fonts
const argent = localFont({
	src: [
		{
			path: 'fonts/argent.woff2',
			weight: '300',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/argentRegular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/argentLight.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/argentDemiBold.woff2',
			weight: '700',
			display: 'swap',
			style: 'normal',
		},
	],
});

const avenir = localFont({
	src: [
		{
			path: 'fonts/avenirRegular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/avenirMedium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/avenirDemi.woff2',
			weight: '600',
			display: 'swap',
			style: 'normal',
		},
	],
});

const urbanist = localFont({
	src: [
		{
			path: 'fonts/urbanist.woff2',
			display: 'swap',
			style: 'normal',
		},
	],
});

const caveat = localFont({
	src: [
		{
			path: 'fonts/caveatRegular.ttf',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/caveatBold.ttf',
			display: 'swap',
			style: 'normal',
			weight: '700',
		},
	],
});

const Variables = createGlobalStyle`${css`
	:root {
		//! Fonts
		--argent: ${argent.style.fontFamily};
		--avenir: ${avenir.style.fontFamily};
		--urbanist: ${urbanist.style.fontFamily};
		--caveat: ${caveat.style.fontFamily};

		//! Spacings
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		${spaceGenerator()}

		//! Transition
        --trTime: 0.3s;

		//! Line Heights
		--lineHeightS: 1.2;
		--lineHeightM: 1.3;
		--lineHeightL: 1.5;

		//! Colors
		${colorGenerator()}

		//! Screen Sizes
		--desktopSizeXL: ${(props) => props.theme.mediaQuery.desktopSizeXL};
		--desktopSizeL: ${(props) => props.theme.mediaQuery.desktopSizeL};
		--desktopSizeM: ${(props) => props.theme.mediaQuery.desktopSizeM};
		--desktopSizeS: ${(props) => props.theme.mediaQuery.desktopSizeS};
		--tabletSize: ${(props) => props.theme.mediaQuery.tabletSize};
		--tabletSizeS: ${(props) => props.theme.mediaQuery.tabletSizeS};

		//! Grid Column Distance
		--colPadding: calc(var(--gutter) / 2);

		//! Letter Spacings (3%)
		--letterSpacingH1: calc(var(--h1) * 3 / 100);
		--letterSpacingH2: calc(var(--h2) * 3 / 100);
		--letterSpacingH3: calc(var(--h3) * 3 / 100);
		--letterSpacingH4: calc(var(--h4) * 3 / 100);
		--letterSpacingH5: calc(var(--h5) * 3 / 100);
		--letterSpacingH6: calc(var(--h6) * 3 / 100);
		--letterSpacingP1: calc(var(--p1) * 3 / 100);
		--letterSpacingP2: calc(var(--p2) * 3 / 100);
		--letterSpacingP3: calc(var(--p3) * 3 / 100);
		--letterSpacingP4: calc(var(--p4) * 3 / 100);
		--letterSpacingP5: calc(var(--p5) * 3 / 100);
		--letterSpacingP6: calc(var(--p6) * 3 / 100);
		--letterSpacingP7: calc(var(--p7) * 3 / 100);

		//! Common Variables
		--headerToHeroSectionDistance: 135px;
		--textToBannerDistance: var(--btnToBannerDistance);
		--textToContentDistance: var(--btnToBannerDistance);
		--btnToContentDistance: var(--btnToBannerDistance);
	}

	/* //! 2560 */
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeXL}) {
		:root {
			--h1: 160px;
			--h2: 104px;
			--h3: 96px;
			--h4: 72px;
			--h5: 56px;
			--h6: 48px;
			--p1: 40px;
			--p2: 28px;
			--p3: 24px;
			--p4: 20px;
			--p5: 18px;
			--p6: 18px;
			--p7: 16px;

			//! Global Variables
			--contPaddingLR: var(--sp45x);
			--gutter: var(--sp4x);
			--sectionDistance: var(--sp30x);

			--titleToTextDistance: var(--sp4x);
			--textToBtnDistance: var(--sp7x);
			--btnToBannerDistance: var(--sp13x);
			--headerSpacing: 136px;
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		:root {
			--h1: 120px;
			--h2: 88px;
			--h3: 80px;
			--h4: 64px;
			--h5: 48px;
			--h6: 40px;
			--p1: 32px;
			--p2: 24px;
			--p3: 20px;
			--p4: 18px;
			--p5: 16px;
			--p6: 16px;
			--p7: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp35x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp25x);

			--titleToTextDistance: var(--sp3x);
			--textToBtnDistance: var(--sp5x);
			--btnToBannerDistance: var(--sp10x);
			--headerSpacing: 104px;
		}
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		:root {
			--h1: 112px;
			--h2: 80px;
			--h3: 72px;
			--h4: 56px;
			--h5: 48px;
			--h6: 40px;
			--p1: 32px;
			--p2: 24px;
			--p3: 20px;
			--p4: 16px;
			--p5: 16px;
			--p6: 14px;
			--p7: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp18x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp21x);

			--titleToTextDistance: var(--sp3x);
			--textToBtnDistance: var(--sp5x);
			--btnToBannerDistance: var(--sp8x);
			--headerSpacing: 96px;
		}
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		:root {
			--h1: 96px;
			--h2: 72px;
			--h3: 64px;
			--h4: 48px;
			--h5: 40px;
			--h6: 32px;
			--p1: 28px;
			--p2: 24px;
			--p3: 20px;
			--p4: 16px;
			--p5: 16px;
			--p6: 14px;
			--p7: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp15x);
			--gutter: var(--sp3x);
			--sectionDistance: var(--sp17x);

			--titleToTextDistance: var(--sp3x);
			--textToBtnDistance: var(--sp4x);
			--btnToBannerDistance: var(--sp7x);
			--headerSpacing: 80px;
		}
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		:root {
			--h1: 96px;
			--h2: 72px;
			--h3: 64px;
			--h4: 48px;
			--h5: 40px;
			--h6: 32px;
			--p1: 28px;
			--p2: 24px;
			--p3: 20px;
			--p4: 16px;
			--p5: 16px;
			--p6: 14px;
			--p7: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp10x);
			--gutter: var(--sp2-5x);
			--sectionDistance: var(--sp17x);

			--titleToTextDistance: var(--sp3x);
			--textToBtnDistance: var(--sp4x);
			--btnToBannerDistance: var(--sp7x);
			--headerSpacing: 80px;
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		:root {
			--h1: 72px;
			--h2: 56px;
			--h3: 48px;
			--h4: 40px;
			--h5: 32px;
			--h6: 24px;
			--p1: 24px;
			--p2: 24px;
			--p3: 20px;
			--p4: 16px;
			--p5: 16px;
			--p6: 14px;
			--p7: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp5x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp13x);

			--titleToTextDistance: var(--sp3x);
			--textToBtnDistance: var(--sp3x);
			--btnToBannerDistance: var(--sp5x);
			--headerSpacing: 72px;
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		:root {
			--h1: 48px;
			--h2: 40px;
			--h3: 32px;
			--h4: 32px;
			--h5: 24px;
			--h6: 20px;
			--p1: 20px;
			--p2: 20px;
			--p3: 18px;
			--p4: 16px;
			--p5: 16px;
			--p6: 14px;
			--p7: 14px;

			//! Global Variables
			--contPaddingLR: var(--sp2x);
			--gutter: var(--sp2x);
			--sectionDistance: var(--sp10x);

			--titleToTextDistance: var(--sp2x);
			--textToBtnDistance: var(--sp3x);
			--btnToBannerDistance: var(--sp5x);
			--headerSpacing: 56px;
		}
	}
`}`;

export default Variables;
