import Cookie from 'js-cookie';

export const stringToLetters = (text) => {
	let wordLength = text.split(' ');

	return wordLength.map((i, k) => {
		return (
			<span
				key={k}
				className='word'>
				{i}
			</span>
		);
	});
};

export const setCookie = (key, cookie) => {
	Cookie.set(key, cookie);
};

export const removeCookie = (key) => {
	Cookie.remove(key);
};

export const getRequestCookies = (ctx, key) => {
	return ctx?.req?.cookies?.[key];
};

export const getCookie = (key) => {
	return Cookie.get(key);
};

export const getParamsByKey = (params, key) => {
	return params.get(key)?.split(',');
};

export const createQueryString = ({ searchParams, typeId, type, multiple }) => {
	const params = new URLSearchParams(searchParams);

	let newVal = typeId?.toString();

	if (multiple) {
		let paramsArr = getParamsByKey(params, type);

		if (paramsArr) {
			paramsArr = paramsArr.indexOf(newVal) > -1 ? paramsArr.filter((k) => k !== newVal) : [...paramsArr, newVal];
			newVal = paramsArr.join(',');
		}
	}

	if (!newVal || params.has(type, newVal)) {
		params.delete(type);
	} else {
		params.set(type, newVal);
	}

	if (type != 'page' && params.has('page')) {
		params.delete('page');
	}

	return params.toString();
};

export const objectToFormData = (data, formData, parentKey) => {
	const newFormData = formData || new FormData();

	if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
		Object.keys(data).forEach((key) => {
			objectToFormData(data[key], newFormData, parentKey ? `${parentKey}[${key}]` : key);
		});
	} else {
		const value = data == null ? '' : data;

		newFormData.append(parentKey, value);
	}

	return newFormData;
};

export const encodeTitle = (str) => {
	return encodeURI(str.toLowerCase().replaceAll(/[^\w\s]/g, '').replaceAll(/\s+/g, '-'));
}