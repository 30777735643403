import { appConfig } from "@/app/appConfig";

export const POSTS = [
  {
    id: 1,
    title: "A timeless classic in a new way",
    text: `81.6% of capital owners do not share details about assets with their family members. They view early disclosure as risky, while understanding that late disclosure is simply impossible. It is based on 'Penguin Analytics', research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

In the event of an emergency, conveying all necessary details to family members can be challenging or impossible. Many believe that existing methods are either unreliable or cumbersome. However, the solution can be simple: grab a sheet of paper and a black marker.

Write down a list of your assets, capital, and their locations. Make several copies of this document. Use a black marker to obscure different parts of the information on each copy (this can also be done digitally in MS Word).

To ensure the information cannot be read through the redactions, photocopy these documents again. Distribute the lists to different family members.

Now, your family has crucial information that they can use in a critical moment, but they will need to come together to combine the pieces of the puzzle.

Risks: this method is not perfect, but is certainly better than having no plan at all. It works best if the family members are in reasonably good relationships with each other.`,
    metaDescription: `In the event of an emergency, conveying all necessary details to family members can be challenging or impossible. Many believe that existing methods are unreliable.`,
    ordering: 15,
  },
  {
    id: 2,
    title: "Playing by one side’s rules",
    text: `42.8% of wealth owners are not aware of KYC risks and issues related to banks’ KYC (Know Your Client) procedures. It is based on 'Penguin Analytics', research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

Don’t get complacent, if you pass bank compliance when opening an account. Banks regularly conduct reviews of their clients and send requests for additional documentation. They may ask for statements or recommendation letters.

Get ready for it in advance; otherwise, you may not have sufficient time to gather the necessary documents, leading to the refusal of transactions.

To stay ahead, request recommendation letters, annual, and semi-annual statements from all banks where you hold accounts every six months. Typically, bank inquiries cover the current and previous year, and recommendation letters are valid for six to twelve months.

To save time, create a template that can be sent to all relevant banks. Most banks accept free-form requests, but some may require you to use their specific templates. In such cases, you’ll need to send separate requests.`,
    metaDescription: `Don’t get complacent, if you pass bank compliance when opening an account. Banks regularly conduct reviews of clients and send requests for documentation.
    `,
    ordering: 13,
  },
  {
    id: 3,
    title: "Rhino running",
    text: `A rhinoceros runs fast and sees poorly, but its weight makes it a problem for anything in its path. Banks and partners don’t listen to you; they evaluate a person based on their digital profile. Services such as World-Check or Lexis Nexis are the sources of this data. These platforms were created to check counterparties for involvement in illegal financial activities.

However, in reality, they contain information on millions of people. These services are often associated with scandals and data leaks, unjust categorization of individuals as suspicious, and the inclusion of data from irrelevant sources, yet they continue to be widely used.

Do not leave this issue unattended. Request information about yourself regularly, at least every six months.

You don't need to have done something wrong; it's enough for the World-Check algorithm to flag you as suspicious, or for someone in your contacts to be linked to questionable transactions. There's also the risk of database errors or incorrect interpretations.

World-Check is a black box that significantly impacts your capital and assets, potentially leading to loan refusals, blocked transactions, denied bank accounts, and even revocation of residence permits or citizenship. Bank compliance departments work solely with documents and digital traces, so if you face unexplained refusals or biased treatment, World-Check could be the reason.`,
    metaDescription: `A rhinoceros runs fast and sees poorly, but its weight makes it a problem. Banks don’t listen to you; they evaluate a person based on digital profile. `,
    ordering: 12,
  },
  {
    id: 4,
    title: "The rearview mirror or the history of your money",
    text: `92% of capital founders underestimate the importance of Source of Wealth Essay (SoWE) with a proof of Continuity of Ownership. They consider it to be a document of little importance. It is based on 'Penguin Analytics', research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.
  
Recently, banking compliance procedures have become increasingly stringent, and it is likely that they will only get worse in the future. Primarily, they request documents proving the continuity of ownership. (Continuity of ownership refers to the history of the origin of your wealth).

The timeframe for these requests has also changed: initially, regulators were interested in information from the past 6 months, then from the past year, and then from the past 3 years. These days, you may be required to provide data from the past 10 years.

If you have not yet recovered your data, each day you wait only worsens your situation, as your data becomes outdated while the depth of bank inquiries increases. If you do not start addressing this now, eventually these two trends will intersect, and you will find yourself in an untenable situation.

Start by recovering copies of documents from the past three years and then delve deeper. Recovering some documents may be difficult or impossible, but by starting this process now, you will already be ahead of many.

* Continuity of ownership - the history of all your assets`,
    metaDescription: `
Recently, banking compliance procedures have become increasingly stringent, and it is likely that they will only get worse in the future.`,
    ordering: 11,
  },
  {
    id: 5,
    title: "Red button",
    text: `93% of wealth owners admit that they have no understanding of how their family will act in the event of force majeure. It is based on 'Penguin Analytics', research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

In the subway, on trains, and at workplaces, there is always a red button for emergencies. In human life, the variations of unexpected events are much greater. Nevertheless, statistics show that 99.2% of wealthy families do not have a precise action plan for emergencies.

Have you thought about what you will do and what plan your family will follow if such an event occurs? To avoid being part of this majority statistic, prepare in advance by developing several future scenarios. Even a simple list of basic actions and assets will prepare you and your family for the most unexpected events.

Remember to update the plan every six months. In the event of your sudden absence, the survival of your entire family and the preservation of assets will entirely depend on having clearly formulated and planned actions.`,
    metaDescription: `While subways and workplaces have red emergency buttons, 99.2% of wealthy families lack a clear action plan for life's unexpected events. Plan ahead.`,
    ordering: 10,
  },
  {
    id: 6,
    title: "Ski slopes",
    text: `Only 4.02% realize that if their bank requests verification of a frozen transaction – they will have just three days to provide the documents. It is based on 'Penguin Analytics', research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

A black slope can be easier with good snow than a blue slope with bad snow. Often, the reasons behind account freezes or bank-canceled transactions are not immediately clear. Regulatory criteria for deeming client transactions suspicious are vague.

Sometimes, this judgment is made because a client has a large number of diverse transactions. This can lead to a transaction freeze and a request for additional documentation.

To avoid this, separate your bank accounts by transaction types, and ideally, conduct different types of transactions through different banks. This reduces the frequency of regular and unexpected requests and helps you manage and respond to bank inquiries.

For example, use separate accounts for dividends, current expenses, bonuses, and investment income. This approach saves time, is convenient, and reduces compliance risks.

Of course, dividing assets among multiple accounts and banks may increase the complexity of financial planning. Additionally, if you mistakenly mix up the accounts and conduct an atypical transaction, the regulator will likely send you a request.`,
    metaDescription: `Often, the reasons behind account freezes or bank-canceled transactions are not immediately clear. Regulatory criteria for deeming client transactions suspicious are vague.`,
    ordering: 9,
  },
  {
    id: 7,
    title: "Follow the trail",
    text: `Private transactions from $134 000 to $4M, often scrutinized for KYC compliance, are mostly initiated by capital founders with assets between $3M and $99M. It is based on 'Penguin Analytics', research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

You’ve sent a payment to another country, and it’s taking a long time to reach its destination — this is a common scenario. People check the status of the payment with the sending bank, not realizing that there can be at least four intermediaries involved in the payment chain.

If any bank in this chain holds the payment longer than usual, it’s highly likely that you’ll be required to provide more detailed KYC (Know Your Customer) data next time, even if the payment goes through this time.

To be prepared and to track your transactions, you can use services to monitor SWIFT payments. These services track the status of your cross-border transactions in real time and notify you of any delays.

You can subscribe to paid versions or use free alternatives available online or through Telegram bots. If you don’t want to spend time on this, you can delegate the task to assistant, who can also monitor the transactions. However, keep in mind the risk of disclosing confidential information, as the data will show either the amount or the purpose of the transaction.

Additionally, a major drawback of these services is that they provide data only in real time and do not maintain statistics for individuals.`,
    metaDescription: `You’ve sent a payment to another country, and it’s taking a long time to reach its destination — this is a common scenario. People check the status of the payment with the sending bank, not realizing that there can be at least four intermediaries involved in the payment chain.`,
    ordering: 8,
  },
  {
    id: 8,
    title: "The “Alien - Own” strategy: securing your family’s future",
    text: `92% of additionally surveyed lawyers claimed that in many cases having detailed information about assets is more important than having documents. It is based on 'Penguin Analytics', research of 13 500 respondents from 18 countries, with Net Worth from $3 million to $99 million.

In today’s world, our lives are diversified not only by types of assets, but also by the jurisdictions where these assets are located. Does ownership of an asset give you your own rights and how can we transfer this capital to heirs without causing them headaches? What rules will govern the inheritance of these assets?

The simplest solution might be to draft a will for each individual foreign asset located in its respective country. To create this document, you usually don’t need to travel; in most cases, it can be done at a consulate without the other party’s presence. The key is to plan how to transfer this information to your family in the event of an emergency.

Keep in mind that inheritance can be a lengthy and costly process, and until it is finalized, your heirs will have limited access to the assets. This poses a risk of improper management, potentially leading to asset loss. Additionally, inheritance may be subject to high taxes in the relevant country, adding to the financial burden.`,
    metaDescription: `In today’s world, our lives are diversified not only by types of assets, but also by the jurisdictions where these assets are located.What rules will govern the inheritance of these assets?`,
    ordering: 7,
  },
  {
    id: 9,
    title: "SWIFT",
    text: `Few people closely track their international payments via SWIFT trackers, though it’s worth doing as it can reveal potential problems. When you send a USD payment, it typically passes through 3-5 banks. If the payment gets delayed, it's likely that one of these banks has compliance or KYC-related questions for the sender.

The bank will request the sender’s client profile from the previous bank in the chain. After reviewing the sender’s identity and finances, the bank either processes the payment, returns it, or freezes it. All banks in the chain can be held responsible if a gray-area or illegal payment is flagged, so they carefully check every transaction to avoid liability.

If a bank requests more information about a payment, all other banks in the chain will be aware. Your sending bank will likely notice and may monitor your future transactions more closely or review your profile.

Tracking payments helps you understand where and why delays occur, even if the payment eventually clears. You can delegate this task to an assistant, as some SWIFT trackers allow tracking by payment number without revealing sensitive details.

It’s also essential to maintain a well-prepared client file with your banks—an important topic for another discussion.`,
    metaDescription: `Few people closely track the movement of their international payments through SWIFT trackers (many of which are publicly available).`,
    ordering: 6,
  },
  {
    id: 10,
    title: "Cryptocurrency",
    text: `I have nothing against crypto. Quite the opposite. But everything must be done carefully. What’s the main issue with large-scale crypto transactions? In 91% of cases, converting from fiat to crypto and back breaks the chain of ownership, limiting capital mobility. Why?

Imagine you have $1M in business dividends. You have all the necessary documentation, making you a respectable capital owner to banks. Now, you decide to purchase cryptocurrency, like Bitcoin or USDT. This is a standalone transaction. Later, when you exchange the crypto back into fiat (whether tomorrow or in a year), that becomes a separate, independent transaction. These two operations are not formally linked.

As a result, you’ve traded clean capital for crypto, but when exiting, you no longer have the supporting documentation. The chain of continuous ownership is broken. For small sums, this isn't a big issue, but for large amounts, it becomes a red flag for banks. The receiving bank will demand a clear ownership history, and without it, your funds raise suspicion.

Banks today require detailed transparency at every stage of capital accumulation—they want to trace every dollar. When you break the ownership chain with crypto, it becomes difficult, if not impossible, to justify the source of your funds.

While this problem can be avoided, statistics show that only 9% of people worry about it in advance.`,
    metaDescription: `I have nothing against crypto. Quite the opposite. But everything must be done carefully. What’s the main issue with large-scale crypto transactions?`,
    ordering: 5,
  },
  {
    id: 11,
    title: "Vertical and Horizontal Family Capital",
    text: `There are many myths and illogical behaviors among wealthy families in our field. Many of these have been analyzed in Penguin Analytics.

One prevalent myth is that family money typically transfers vertically (to children). In reality, most transfers are horizontal (to spouses), both planned and unexpected.

Analysts (Owner.One, UBS, and local banks) estimate that over the next 10-20 years, $75-95 trillion in capital will be transferred within families. In the next few years alone, $8-10 trillion will be moved horizontally to spouses. This capital will likely stay at the horizontal level for another 10-15 years before moving vertically to children.

Key insight: much of the capital loss happens during the transfer itself, not afterward, doubling the actual loss rate. Founders need to pass on not only their wealth but also its detailed and formalized history to meet the increasing KYC (Know Your Customer) demands from financial institutions.

Without the founder’s active involvement, it’s almost impossible to reconstruct the capital’s history, leaving family members vulnerable when questions arise about the funds' origins. A surprising 42.86% of capital founders don’t know the details needed for KYC preparation, and an alarming 88.06% of their family members are unaware of these requirements.

Only 4.50% of family heads understand that inaction now merely delays the problem, passing it on to their family. Preparing for this is crucial, but that’s a discussion for another time. Recognizing the complexity of capital transfer is the first step.`,
    metaDescription: `There are many myths and illogical behaviors among wealthy families in our field. Many of these have been analyzed in 'Penguin Analytics'.`,
    ordering: 4,
  },
  {
    id: 12,
    title: "Your Proxy’s Personal Life – Your Risk",
    text: `Once upon a time, you entrusted part of your wealth to a relative, former classmate, or just a trusted person, making them the formal owner to conceal your involvement. Both of you were relatively young, but as the years went by, your wealth grew, along with social obligations. Now, both of you have families, children, perhaps remarriages, and different life changes, like acquiring new citizenship.

The asset you entrusted them with is no longer entirely yours. If something happens to them, their family may take priority over you, and you could end up with just half, or even one-eighth, of your factory, which they officially own. And if they’ve acquired problematic citizenship, such as U.S. citizenship, returning the asset to you could trigger significant taxes.

Does your proxy have a prenuptial agreement? If it’s in your favor, it might not help much in practice, but if it favors their family, it becomes your problem. Do they have large loans with personal guarantees or risks of subsidiary liability? The list of potential complications goes on.

In many cases, such arrangements are perfectly legal. However, legality doesn’t eliminate the issues described above. Talk to your proxy, assess their situation across all risk zones, model potential consequences, and prepare the necessary documents to protect your interests.

This note doesn’t cover official managers with formal management agreements. That’s a topic for another note.`,
    metaDescription: `Once upon a time, you entrusted part of your wealth to a relative, former classmate, or just a trusted person, making them the formal owner to conceal your involvement.`,
    ordering: 14,
  },
  {
    id: 13,
    title: "I'll Think About That Tomorrow",
    text: `Founders of capital almost never share information about their wealth and assets with their family in advance. In the total majority of cases, they have no opportunity to do so after something has happened.

According to statistics, 89.13% of wealth creators understand everything but do nothing. Why? Most of the responses boil down to the classic "I'll think about it tomorrow" from Scarlett O'Hara in Gone with the Wind.

The transfer of capital happens once in a lifetime. Discovering its outcomes, correcting, and restarting it is almost always impossible. In this field, there are no "drafts".

Another stimulus for the development of the Scarlett syndrome is the founders' realization that the effectiveness of traditional tools is already below the minimally acceptable threshold of reliability, yet they require disproportionately high efforts and resource expenditures.`,
    metaDescription: `Founders of capital almost never share information about their wealth and assets with their family in advance.`,
    ordering: 0
  },
  {
    id: 14,
    title: "Game over",
    text: `What if a wealthy capital owner suddenly becomes incapacitated? In 90% of cases, they haven’t shared any information about their wealth with family.

Assets like accounts, safe deposit boxes, brokers, companies, debts, credits, partners, bonds, real estate, stocks, key contacts, proxies, funds, and investments may be lost or become costly to access. Much of the family’s wealth remains unknown.

In families worth up to $100 million, 31% to 73% of assets are lost during the transition — a percentage increasing over the past 30 years. Since the 1990s, information has become more valuable than documents, making information asymmetry and capital loss dramatically worse. Without complete information, accessing assets becomes impossible, even if documents can be reconstructed digitally.

By 2024, 48% of capital owners believed their families couldn’t access their assets due to lack of information, and 23.7% were willing to forfeit up to 25% of their wealth to ensure the rest reaches their family.`,
    metaDescription: `What if a wealthy capital owner suddenly becomes incapacitated? In 90% of cases, they haven’t shared any information about their wealth with family.`,
    ordering: 1
  },
  {
    id: 15,
    title: "KYC? Not About Me!",
    text: `For various internal reasons, banks — especially — may halt a client's transaction or even block an account. This stems from numerous and often opaque KYC (Know Your Client) requirements, as well as its subtypes like KYT (Know Your Transactions), KYB (Know Your Business), and others.

The majority of clients (82%) have encountered issues with banking or brokerage transactions to some extent. Yet only 17% of them associated these problems with their own KYC.

Of these 17%, only 29% claimed to have a clear understanding of the preventive actions necessary to minimize risks. Merely 4% grasp the depth of issues arising from KYC.

At the first signs of trouble, swift action is essential; otherwise, the situation will worsen, and the bank's demands will escalate. Those who proactively attend to their KYC — keeping maximum documentation readily available, maintaining an up-to-date Source of Wealth Explanation (SoWE), confirming continuous ownership, and adhering to other formal goodwill parameters — are the ones who come out ahead.`,
    metaDescription: `For various internal reasons, banks — especially — may halt a client's transaction or even block an account.`,
    ordering: 2
  },
  {
    id: 16,
    title: "Eliminating Information Asymmetry",
    text: `The vast majority (87.10%) of capital owners do not have precise data and detailed attributes of their assets. They also do not have documents for their assets readily available or direct access to them. They cannot independently transfer data to family members and cannot monitor the actions of trusted persons.

What is the alternative to traditional mechanisms attempting to ensure the transfer of capital and assets to their family members?

It is necessary to achieve confidential information storage, and most importantly — the transfer of information from the founder to family members precisely on time — not earlier or later, but at the exact moment when something happens. Transfer automatically and without the involvement of third parties.

Until 2015, such a result was impossible; now, technology can provide this.

The development of technologies and market demand have led to the creation of a tool like the Repository of Data on Assets and Capital. The repository's effectiveness is up to 99.94%.`,
    metaDescription: `The vast majority (87.10%) of capital owners do not have precise data and detailed attributes of their assets.`,
    ordering: 3
  },
];

export const ANNOUNCMENTS = [
  {
    title: "How to safeguard against misconduct by asset managers?",
    status: "awaiting_publication",
  },
  {
    title: "How should I approach discussing wealth with my family?",
    status: "awaiting_publication",
  },
  {
    title: "What strategies can be employed to create a positive impression with the Compliance Department?",
    status: "awaiting_answer",
  },
  {
    title: "How to streamline and organize the management and storage of financial information?",
    status: "awaiting_answer",
  },
];
