export const pushDataLayer = ({ event, target, element, ...rest }) => {
  window.dataLayer = window.dataLayer || [];
  const obj = { 
    event, 
    target: null,
    element: null,
    elementClasses: null,
    elementText: null,
    pageURL: null,
    popupTitle: null,
    ...rest,
  };
  if (target) {
    Object.assign(obj, {
      target: target,
    });
  }
  if (element) {
    Object.assign(obj, {
      element,
      elementClasses: element.className,
      elementText: element.textContent,
    });
  }
  if (location?.href) {
    Object.assign(obj, {
      pageURL: location.href,
    });
  }
  window.dataLayer.push(obj);
};
