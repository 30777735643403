export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
			darkHeader: false,
		},


		//! Recourses
		legend: {
			path: '/legend',
			slug: 'legend',
			name: 'True Story',
			isFooter: 'col-2',
			isHeader: true,
			darkHeader: false,
		},

		analytics: {
			path: '/analytics',
			slug: 'analytics',
			name: 'analytics',
			isFooter: 'col-3',
			isHeader: true,
			darkHeader: false,
		},

		product: {
			path: '/product',
			slug: 'product',
			name: 'How it works',
			isFooter: 'col-1',
			isHeader: true,
			darkHeader: false,
		},

		aboutUs: {
			path: '/about-us',
			slug: 'about-us',
			name: 'aboutUs',
			isFooter: 'col-2',
			isHeader: true,
			darkHeader: false,
		},

		pricing: {
			path: '/pricing',
			slug: 'pricing',
			name: 'start using',
			// isFooter: 'col-1',
			isHeader: true,
			darkHeader: false,
		},

		blog: {
			path: '/blog',
			slug: '/blog/:slug',
			name: 'blog',
			isFooter: 'col-1',
			isHeader: false,
			darkHeader: false,
		},

		contacts: {
			path: '/contacts',
			slug: 'contacts',
			name: 'contacts',
			isFooter: 'col-2',
			darkHeader: false,
		},

		//! Company

		helpCenter: {
			path: '/help-center',
			slug: 'help-center',
			name: 'helpCenter',
			isFooter: 'col-3',
			darkHeader: false,
		},

		//! Legal
		termsOfService: {
			path: '/terms-of-service',
			slug: 'terms-of-service',
			name: 'termsOfService',
			isFooter: 'col-4',
			darkHeader: false,
		},

		privacyPolicy: {
			path: '/privacy-policy',
			slug: 'privacy-policy',
			name: 'privacyPolicy',
			isFooter: 'col-4',
			darkHeader: false,
		},

		cookiesPolicy: {
			path: '/cookies-policy',
			slug: 'cookies-policy',
			name: 'cookiesPolicy',
			isFooter: 'col-4',
			darkHeader: false,
		},
	},
};
